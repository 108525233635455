import { TFiltersTours, TNamesParamsTourFilter } from 'shared/types/tours.types';
import { BaseSelect } from '../../../shared/ui/BaseSelect/BaseSelect';

const itemsMonthTour = [
  {
    value: '',
    label: 'Круглый год',
  },
  {
    value: 'Январь',
    label: 'Январь',
  },
  {
    value: 'Февраль',
    label: 'Февраль',
  },
  {
    value: 'Март',
    label: 'Март',
  },
  {
    value: 'Апрель',
    label: 'Апрель',
  },
  {
    value: 'Май',
    label: 'Май',
  },
  {
    value: 'Июнь',
    label: 'Июнь',
  },
  {
    value: 'Июль',
    label: 'Июль',
  },
  {
    value: 'Август',
    label: 'Август',
  },
  {
    value: 'Сентябрь',
    label: 'Сентябрь',
  },
  {
    value: 'Октябрь',
    label: 'Октябрь',
  },
  {
    value: 'Ноябрь',
    label: 'Ноябрь',
  },
  {
    value: 'Декабрь',
    label: 'Декабрь',
  },
];

type TToursFiltersBySeason = {
  filters: string[];
  onClick: <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours[TName]) => void;
};

export const ToursFiltersBySeason = ({ filters, onClick }: TToursFiltersBySeason) => {
  const handleSelect = (value: string) => {
    const updatedFilters = filters.includes(value) ? filters.filter((item) => item !== value) : [...filters, value];
    onClick('season', updatedFilters);
  };

  return (
    <div className='params-filters-group'>
      <div className='params-filters-item'>
        <BaseSelect title={'Месяц'} items={itemsMonthTour} handlerSelect={handleSelect} />
      </div>
    </div>
  );
};
