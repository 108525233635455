import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { selectTours } from 'manageStore/tours/tours.select';
import { fetchTours } from 'manageStore/tours/tours.thunk';
import Header from 'components/Header/Header';
import { HomeTours } from 'widgets/lib/homeMain/HomeTours/HomeTours';
import { HomeIntro } from 'widgets/lib/homeMain/HomeIntro/HomeIntro';
import { HomeFlora } from 'widgets/lib/homeMain/HomeFlora/HomeFlora';
import { HomeFauna } from 'widgets/lib/homeMain/HomeFauna/HomeFauna';
import { HomeImpression } from 'widgets/lib/homeMain/HomeImpression/HomeImpression';
import { HomeNews } from 'widgets/lib/homeMain/HomeNews/HomeNews';
import { HomeFaq } from 'widgets/lib/homeMain/HomeFaq/HomeFaq';
import { HomeFeedback } from 'widgets/lib/homeMain/HomeFeedback/HomeFeedback';
import Footer from 'widgets/lib/Footer/Footer';
import { HomePartners } from 'widgets/lib/homeMain/HomePartners/HomePartners';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';

export const HomePage = () => {
  const dispatch = useDispatch();
  const tours = useSelector(selectTours);

  useEffect(() => {
    if (tours && !tours.length) {
      dispatch(fetchTours());
    }
  }, [tours]);

  return (
    <>
      <Helmet>
        <title>Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <div className='content'>
        <AuthPortal />
        <Header isWightText isFixed />
        <HomeIntro />
        <HomeFlora />
        <HomeFauna />
        <HomeImpression />
        <HomeTours />
        <HomeNews />
        <HomePartners />
        <HomeFaq />
        <HomeFeedback />
      </div>
      <Footer />
    </>
  );
};
