import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { fetchPartners } from 'manageStore/partners/partners.thunk';
import { selectLoading, selectPartners } from 'manageStore/partners/partners.select';
import { useSwipe } from 'shared/hooks/useSwipe';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import Loading from 'components/Loading/Loading';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { PartnerCard } from 'entities/partner/PartnerCard/PartnerCard';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';

const settings: Settings = {
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToScroll: 3,
  nextArrow: <ButtonSlider right dark />,
  prevArrow: <ButtonSlider left dark />,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        vertical: true,
      },
    },
  ],
};

export const HomePartners = () => {
  const dispatch = useDispatch();
  const partners = useSelector(selectPartners);
  const isLoading = useSelector(selectLoading);
  const [startSwipe, endSwipe, isSwipe] = useSwipe();
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const currentPartners = useMemo(() => {
    if (!partners) {
      return [];
    } else {
      return isMobile && partners.length > 3 ? partners.slice(-3) : partners;
    }
  }, [partners, isMobile]);

  const openPartner = (id: number | string) => {
    if (!isSwipe) {
      const newTab = window.open(`partners/${id}`, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. It might be blocked by the browser.');
      }
    }
  };

  useEffect(() => {
    if (!partners) {
      dispatch(fetchPartners());
    }
  }, [partners, dispatch]);

  return (
    <section className='home-partners'>
      <div className='container home-partners__container'>
        <TitleSectionMain
          className='home-partners__title'
          title={
            <>
              Организаторы <span className='red'>путешествий</span>
            </>
          }
          subtitle='Вместе путешествия ярче! Здесь вы можете выбрать проводника в удивительный мир плато Путорана или найти всё необходимое для самостоятельного изучения бескрайнего Таймыра!'
        />
        {isLoading ? (
          <Loading />
        ) : partners?.length ? (
          <Slider
            className='home-partners__slider'
            {...settings}
            swipeEvent={startSwipe}
            slidesToShow={currentPartners.length > 2 ? 3 : 2}
          >
            {currentPartners.map((card) => (
              <PartnerCard partner={card} key={card.id} openPartnerOut={openPartner} endSwipe={endSwipe} />
            ))}
          </Slider>
        ) : (
          <>Уупс, пока что ничего нет!</>
        )}
        <Link className='home-partners__btn' to={'/partners'}>
          Все партнеры
          <ArrowButton />
        </Link>
      </div>
    </section>
  );
};
