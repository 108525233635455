type TCookieNotificationProps = {
  handleClickAccept: () => void;
};

const CookieNotification = ({ handleClickAccept }: TCookieNotificationProps) => {
  return (
    <div className='cookie-notification'>
      <p className='cookie-notification__text text'>
        Для улучшения Вашего взаимодействия с сайтом мы используем файлы cookie. Оставаясь на сайте, Вы принимаете наши{' '}
        <a href={'useragreement'} className='link'>
          Условия использования
        </a>
        ,{' '}
        <a href={'privacy'} className='link'>
          Политику конфиденциальности
        </a>{' '}
        и соглашаетесь со{' '}
        <a href={'privacy'} className='link'>
          сбором данных
        </a>{' '}
        посредством файлов cookie.
      </p>
      <div className='cookie-notification__accept'>
        <button className='cookie-notification__btn btn' onClick={handleClickAccept}>
          Принять
        </button>
      </div>
    </div>
  );
};

export default CookieNotification;
