import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { DropdownMenu } from '../../shared/ui/DropdownMenu/DropdownMenu';

type TNavigationList = {
  isMobile?: boolean;
};

const NavigationList = ({ isMobile }: TNavigationList) => {
  const { t } = useTranslation();

  return (
    <nav className='header__nav'>
      <ul className='header__menu'>
        {isMobile && (
          <li className='header__menu-item'>
            <NavLink exact to='/' className='header__menu-link'>
              Главная
            </NavLink>
          </li>
        )}
        <li className='header__menu-item'>
          <NavLink to='/tours' className='header__menu-link'>
            Туры и экскурсии
          </NavLink>
        </li>
        <li className='header__menu-item'>
          <a href='/designer' className='header__menu-link'>
            Конструктор путешествий
          </a>
        </li>
        {isMobile && (
          <li className='header__menu-item'>
            <NavLink to='/partners' className='header__menu-link'>
              Организаторы путешествий
            </NavLink>
          </li>
        )}
        <li className='header__menu-item'>
          <NavLink to='/travel-guides' className='header__menu-link'>
            {t('headerMenu.travelGuides')}
          </NavLink>
        </li>
        <li className='header__menu-item'>
          <NavLink to='/blog' className='header__menu-link'>
            {t('headerMenu.news')}
          </NavLink>
        </li>
        <li className='header__menu-item'>
          <DropdownMenu
            label={'Кластер'}
            items={[
              { label: 'О нас', href: 'trk-info' },
              {
                label: 'Организаторы путешествий',
                href: 'partners',
              },
            ]}
            className={'header__menu-link'}
          />
        </li>
      </ul>
    </nav>
  );
};

export default NavigationList;
