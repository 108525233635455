import './homeFindTour.scss';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TFiltersTours } from 'shared/types/tours.types';
import { TRANSFORM_TEXT } from 'shared/constants/const';
import { defaultFiltersTours, setFilters } from 'manageStore/tours/tours.slice';
import { BaseSelect } from 'shared/ui/BaseSelect/BaseSelect';

type TName = keyof Omit<TFiltersTours, 'price'>;

const itemsTypeTour = [
  {
    value: '',
    label: 'Все туры и экскурсии',
  },
  {
    value: TRANSFORM_TEXT.productType.EXCURSION,
    label: TRANSFORM_TEXT.productType.EXCURSION,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.INDUSTRY,
    label: TRANSFORM_TEXT.tourTypeMulti.INDUSTRY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.WATER,
    label: TRANSFORM_TEXT.tourTypeMulti.WATER,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.HIKING,
    label: TRANSFORM_TEXT.tourTypeMulti.HIKING,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.HELICOPTER,
    label: TRANSFORM_TEXT.tourTypeMulti.HELICOPTER,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.SNOWMOBILE,
    label: TRANSFORM_TEXT.tourTypeMulti.SNOWMOBILE,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.JEEP,
    label: TRANSFORM_TEXT.tourTypeMulti.JEEP,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.COMBINED,
    label: TRANSFORM_TEXT.tourTypeMulti.COMBINED,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.ETHNIC,
    label: TRANSFORM_TEXT.tourTypeMulti.ETHNIC,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.CAMPING,
    label: TRANSFORM_TEXT.tourTypeMulti.CAMPING,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.EXPEDITIONARY,
    label: TRANSFORM_TEXT.tourTypeMulti.EXPEDITIONARY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.GASTRONOMIC,
    label: TRANSFORM_TEXT.tourTypeMulti.GASTRONOMIC,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.DIVING,
    label: TRANSFORM_TEXT.tourTypeMulti.DIVING,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.MOTORBIKE,
    label: TRANSFORM_TEXT.tourTypeMulti.MOTORBIKE,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.FAMILY,
    label: TRANSFORM_TEXT.tourTypeMulti.FAMILY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.PHOTOGRAPHY,
    label: TRANSFORM_TEXT.tourTypeMulti.PHOTOGRAPHY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.TRAINING,
    label: TRANSFORM_TEXT.tourTypeMulti.TRAINING,
  },
];

const itemsSeasonTour = [
  {
    value: '',
    label: 'Круглый год',
  },
  {
    value: 'Январь',
    label: 'Январь',
  },
  {
    value: 'Февраль',
    label: 'Февраль',
  },
  {
    value: 'Март',
    label: 'Март',
  },
  {
    value: 'Апрель',
    label: 'Апрель',
  },
  {
    value: 'Май',
    label: 'Май',
  },
  {
    value: 'Июнь',
    label: 'Июнь',
  },
  {
    value: 'Июль',
    label: 'Июль',
  },
  {
    value: 'Август',
    label: 'Август',
  },
  {
    value: 'Сентябрь',
    label: 'Сентябрь',
  },
  {
    value: 'Октябрь',
    label: 'Октябрь',
  },
  {
    value: 'Ноябрь',
    label: 'Ноябрь',
  },
  {
    value: 'Декабрь',
    label: 'Декабрь',
  },
];

const itemsDurationTour = [
  { value: '', label: 'Не важно' },
  { value: '1-4', label: '1-4' },
  { value: '5-10', label: '5-10' },
  { value: '11-15', label: '11-15' },
  { value: '16-20', label: '16-20' },
  { value: '21-25', label: '21-25' },
  { value: '26-30', label: '26-30' },
];

export const HomeFindTour = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState<TFiltersTours>(defaultFiltersTours);

  const changeFormHandler = (name: TName, value: TFiltersTours[TName]) => {
    const field = typeof form[name] === 'string' ? { [name]: value || 'Не выбрано' } : { [name]: value ? [value] : [] };
    setForm((prev) => {
      return { ...prev, ...field };
    });
  };

  const findTour = () => {
    dispatch(setFilters(form));
    history.push({ pathname: 'tours' });
  };

  return (
    <div className='home-find-tour'>
      <div className='home-find-tour__background'></div>
      <BaseSelect
        title={'Вид отдыха'}
        items={itemsTypeTour}
        handlerSelect={(value: string) => changeFormHandler('types', value)}
      />
      <BaseSelect
        title={'Месяц'}
        items={itemsSeasonTour}
        handlerSelect={(value: string) => changeFormHandler('season', value)}
      />
      <BaseSelect
        title={'Количество дней'}
        items={itemsDurationTour}
        handlerSelect={(value: string) => changeFormHandler('duration', value)}
      />
      <button className='home-find-tour__btn' type='button' onClick={findTour}>
        Подобрать
      </button>
    </div>
  );
};
