import { Switch, Route, Redirect } from 'react-router-dom';

// Основные страницы сайта
import { HomePage } from 'pages/HomePage/HomePage';
import { ReadyToursPage } from 'pages/ReadyToursPage/ReadyToursPage';
import { TourDesignerPage } from 'pages/TourDesignerPage/TourDesignerPage';
import { TourItemPage } from 'pages/TourItemPage/TourItemPage';
import TouristPage from 'pages/TouristPage';
import BlogsPage from 'pages/Blogs/BlogsPage';
import SingleNewsPage from 'pages/SingleNewsPage';
import TrkInfoPage from 'pages/AboutUs/TrkInfoPage';
import { NpaPage } from 'pages/NpaPage';
import { TravelGuidesPage } from 'pages/TravelGuidesPage/TravelGuidesPage';

// Страницы личного кабинета
import LoginPage from 'pages/LoginPage';
import AuthPageNew from './newProject/pages/auth';
import ChatPage from 'pages/Account/ChatPage';

// Страницы личного кабинета оператора
import OperatorStatsPage from 'pages/Account/Operator/StatsPage';
import OperatorBProcessPage from 'pages/Account/Operator/BProcessPage';
import OperatorReviewsPage from 'pages/Account/Operator/ReviewsPage';
import { operatorNavs, mobileOperatorNavs } from 'pages/Account/Operator/navigation';
import ToursPage from 'pages/Account/Operator/ToursPage';

// Страницы личного кабинета туриста
import { touristNavs, mobileTouristNavs } from 'pages/Account/Tourist/navigation';
import RequestsPageNew from './newProject/pages/my-applications';
import TouristReviewsPageNew from './newProject/pages/reviews';
import ChatPageNew from './newProject/pages/application-detail';
import RoutesPageNew from './newProject/pages/routes';

// Страницы личного кабинета администратора
import { AdminPage } from 'pages/Admin/AdminPage';
import { PrivacyPage } from 'pages/Policy/PrivacyPage';
import { UseragreementPage } from 'pages/Policy/UseragreementPage';
import { PartnersPage } from 'pages/PartnersPage/PartnersPage';
import { PartnerItemPage } from 'pages/PartnerItemPage/PartnerItemPage';
import { TravelGuideItemPage } from 'pages/TravelGuideItemPage/TravelGuideItemPage';
import TouristProfilePageNew from './newProject/pages/profile';

//deprecated
import RequestsPage from 'pages/Account/RequestsPage';
import ReviewFormPage from 'newProject/pages/create-review';
import SingleBlogPage from './entities/blog/SingleBlogPage';
import RestorePassPage from 'pages/RestorePassPage';
import RegistrationPage from 'pages/RegistrationPage';

import FeedbackPage from 'pages/Feedback/FeedbackPage';

// const AdminPage = React.lazy(() => import('@pages/Admin/AdminPage'));

export const useRoutes = () => {
  return (
    <Switch>
      {/* Основные страницы сайта */}
      <Route path='/' exact>
        <HomePage />
      </Route>

      <Route path='/blog/:id' exact>
        <SingleBlogPage />
      </Route>

      <Route path='/blog' exact>
        <BlogsPage />
      </Route>
      <Route path='/tours' exact>
        <ReadyToursPage />
      </Route>
      <Route path='/tours/:id' exact>
        <TourItemPage />
      </Route>
      <Route path='/partners' exact>
        <PartnersPage />
      </Route>
      <Route path='/partners/:id' exact>
        <PartnerItemPage />
      </Route>
      <Route path='/designer' exact>
        <TourDesignerPage />
      </Route>
      <Route path='/news/:id' exact>
        <SingleNewsPage />
      </Route>
      <Redirect from='/news' to='/trk-info#news' />
      <Route path='/trk-info/' exact>
        <TrkInfoPage />
      </Route>
      <Route path='/trk-info-old/' exact>
        <TrkInfoPage />
      </Route>
      <Route path='/npa/' exact>
        <NpaPage />
      </Route>
      <Route path='/travel-guides' exact>
        <TravelGuidesPage />
      </Route>
      <Route path='/travel-guides/:id' exact>
        <TravelGuideItemPage />
      </Route>
      <Route path='/tourist' exact>
        <TouristPage />
      </Route>
      <Route path='/privacy' exact>
        <PrivacyPage />
      </Route>
      <Route path='/useragreement' exact>
        <UseragreementPage />
      </Route>
      <Route path='/feedback' exact>
       <FeedbackPage />
      </Route>

      {/* Личный кабинет 
      <Route path='/login' exact>
        <LoginPage isAdmin={false} />
      </Route> 
      <Route path='/registration' exact>
        <RegistrationPage />
      </Route>
      <Route path='/restore-pass' exact>
        <RestorePassPage />
      </Route> 
      <Route path='/account' exact>
        <TouristProfilePageNew />
      </Route> */}

      {/* Личный кабинет оператора */}
      <Route path='/account/operator/stats' exact>
        <OperatorStatsPage />
      </Route>
      <Route path='/account/operator/bprocess' exact>
        <OperatorBProcessPage />
      </Route>
      <Route path='/account/operator/tours/draft'>
        <ToursPage />
      </Route>
      <Route path='/account/operator/tours'>
        <ToursPage />
      </Route>
      <Route path='/account/operator/processing-requests' exact>
        <RequestsPage navs={operatorNavs} mobileNavs={mobileOperatorNavs} type={'OPERATOR'} />
      </Route>
      <Route path='/account/operator/new-requests' exact>
        <RequestsPage navs={operatorNavs} mobileNavs={mobileOperatorNavs} type={'OPERATOR'} />
      </Route>
      <Route path='/account/operator/done-requests' exact>
        <RequestsPage navs={operatorNavs} mobileNavs={mobileOperatorNavs} type={'OPERATOR'} />
      </Route>
      <Route path='/account/operator/denied-requests' exact>
        <RequestsPage navs={operatorNavs} mobileNavs={mobileOperatorNavs} type={'OPERATOR'} />
      </Route>
      <Route path='/account/operator/reviews' exact>
        <OperatorReviewsPage />
      </Route>

      {/* Личный кабинет туриста */}
      <Route path='/account/tourist/requests' exact>
        <RequestsPageNew navs={touristNavs} mobileNavs={mobileTouristNavs} type={'TOURIST'} />
      </Route>
      <Route path='/account/tourist/tour/:id/create-review' exact>
        <ReviewFormPage />
      </Route>
      <Route path='/account/tourist/my-reviews' exact>
        <TouristReviewsPageNew />
      </Route>
      {/* 
      TODO MAGIA-19: добавить при доработке маршрутов
      <Route path='/account/tourist/routes' exact>
        <RoutesPageNew />
      </Route> */}

      {/* Личный кабинет администратора */}
      <Route path='/login/admin' exact>
        <LoginPage isAdmin={true} />
      </Route>
      <Route path='/account/admin' exact>
        <AdminPage />
      </Route>
      {/* <Route path='/account/admin/tourcard' exact>
        <ToursCardPage />
      </Route> */}

      {/* Личный кабинет чаты */}
      <Route path='/account/tourist/requests/:id' exact>
        <ChatPageNew type={'TOURIST'} />
      </Route>
      {/*<Route path='/account/tourist/processing-requests/:id' exact>*/}
      {/*  <ChatPage navs={touristNavs} type={'TOURIST'} />*/}
      {/*</Route>*/}
      {/*<Route path='/account/tourist/done-requests/:id' exact>*/}
      {/*  <ChatPage navs={touristNavs} type={'TOURIST'} />*/}
      {/*</Route>*/}
      {/*<Route path='/account/tourist/denied-requests/:id' exact>*/}
      {/*  <ChatPage navs={touristNavs} type={'TOURIST'} />*/}
      {/*</Route>*/}

      <Route path='/account/operator/processing-requests/:id' exact>
        <ChatPage navs={operatorNavs} type={'OPERATOR'} />
      </Route>
      <Route path='/account/operator/new-requests/:id' exact>
        <ChatPage navs={operatorNavs} type={'OPERATOR'} />
      </Route>
      <Route path='/account/operator/done-requests/:id' exact>
        <ChatPage navs={operatorNavs} type={'OPERATOR'} />
      </Route>
      <Route path='/account/operator/denied-requests/:id' exact>
        <ChatPage navs={operatorNavs} type={'OPERATOR'} />
      </Route>

      <Redirect to='/' />
    </Switch>
  );
};
