import { useCallback, useEffect, useState, memo, useRef } from 'react';
import {
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  ArrayInput,
  SelectArrayInput,
  NumberInput,
  ReferenceInput,
  required,
  minValue,
  email,
  DeleteButton,
  Toolbar,
  SaveButton,
  maxLength,
  FormDataConsumer,
} from 'react-admin';
import { useController, useFormState, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import FileInputAdmin from '../../Admin/FileInputAdmin';
import SwitchControl from '../../Admin/SwitchControl';
import Notification from 'shared/lib/notification';
import { STATUS_TYPE, TOURS_FIELDS, TRANSFORM_TEXT } from '../../../shared/constants/const';
import debounce from 'shared/utils/debounce';
import TourService from 'services/tour.service';

import { Recommendations } from 'components/Admin/Tours/Recommendations';
import { useAppSelector } from 'shared/hooks/useAppSelector';


const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
  marginBottom: '20px',
  fontSize: '14px',
};

const itemsSeasonTour = [
  {
    id: 'summer',
    value: TRANSFORM_TEXT.season.SUMMER,
  },
  {
    id: 'autumn',
    value: TRANSFORM_TEXT.season.AUTUMN,
  },
  {
    id: 'winter',
    value: TRANSFORM_TEXT.season.WINTER,
  },
  {
    id: 'spring',
    value: TRANSFORM_TEXT.season.SPRING,
  },
];

const itemsNumberDay = [
  {
    id: 'утро',
    name: 'Утро',
  },
  {
    id: 'день',
    name: 'День',
  },
  {
    id: 'вечер',
    name: 'Вечер',
  },
];

const PostEditToolbar = ({ isCreate, deleteTour }) => {
  const { errors } = useFormState();

  const [clickSave, setClickSave] = useState(false);
  const [isError, setError] = useState(!!Object.keys(errors).length);

  const handleClickSave = () => {
    setClickSave(true);
  };

  useEffect(() => {
    if (clickSave && isError) {
      Notification.error('Заполните обязательные поля');
      setClickSave(false);
    }
  }, [clickSave, isError]);

  useEffect(() => {
    setError(!!Object.keys(errors).length);
  });

  return (
    <Toolbar
      sx={{
        '&.RaToolbar-desktopToolbar': {
          justifyContent: 'space-between',
        },
      }}
    >
      <SaveButton
        label='Сохранить тур'
        onClick={handleClickSave}
        sx={{ backgroundColor: '#285b7d', '&:hover': { backgroundColor: '#1f4762' } }}
      />
      {!isCreate && <DeleteButton label='Удалить' onClick={deleteTour} />}
    </Toolbar>
  );
};

const LocationsTour = () => {
  useController({ name: 'locations', rules: { required: true } });

  return (
    <div style={{ width: '100%'}}>
      <ArrayInput source='locations' label={TOURS_FIELDS.locations} fullWidth sx={styleInBlock}>
        <SimpleFormIterator disableReordering>
          <ReferenceInput source='id' reference='locations' perPage={Infinity} fullWidth>
            <SelectInput optionText='name' optionValue='id' label='Select...' fullWidth validate={[required()]} format={(v) => (v ? v : '')} />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

const NumberDayField = memo(({ productType, days, source }) => {
  const { trigger, formState } = useFormContext();

  const index = Number(source.match(/\d+(?=\.)/g)[0]);

  const handleChangeSelect = () => {
    if (!formState.isSubmitted) return;
    const triggeredFields = days.map((day, index) => `days.${index}.numberDay`);
    setTimeout(() => trigger(triggeredFields), 0);
  };

  const checkNumberDays = useCallback((value, { days }) => {
    let message;
    if (!value || !index || !days) {
      return message;
    }
    const prevDays = days.slice(0, index);

    prevDays.forEach((day) => {
      const isError =
        (day.numberDay === itemsNumberDay[1].id && days[index].numberDay === itemsNumberDay[0].id) ||
        (day.numberDay === itemsNumberDay[2].id &&
          (days[index].numberDay === itemsNumberDay[0].id || days[index].numberDay === itemsNumberDay[1].id));
      if (isError) {
        message = 'Нарушен порядок времени суток';
      }
    });
    return message;
  }, []);

  return productType === TRANSFORM_TEXT.productType.EXCURSION ? (
    <SelectInput
      source={source}
      label={'Утро, день, вечер'}
      fullWidth
      choices={itemsNumberDay}
      validate={[required(), checkNumberDays]}
      onChange={handleChangeSelect}
      format={(v) => (v ? v : '')}
    />
  ) : (
    <TextInput source={source} label='№ дня или дата' fullWidth validate={[required(), maxLength(13)]} format={(v) => (v ? v : '')} />
  );
});

const DaysTour = () => {
  useController({ name: 'days', rules: { required: true } });

  return (
    <div style={{ width: '100%'}}>
      <ArrayInput source='days' label={TOURS_FIELDS.days} fullWidth sx={styleInBlock}>
        <SimpleFormIterator disableReordering>
          <TextInput source='title' label='Заголовок' fullWidth validate={[required()]} />
          <FormDataConsumer>
            {({ formData, getSource }) => (
              <NumberDayField productType={formData.productType} days={formData.days} source={getSource('numberDay')} />
            )}
          </FormDataConsumer>
          <TextInput
            source='desc'
            multiline={true}
            minRows='3'
            maxRows='3'
            label='Описание'
            fullWidth
            inputProps={{
                style: { resize: 'vertical' }
                }}
            validate={[required()]}
          />
          <TextInput
            source='timeline'
            multiline={true}
            minRows='3'
            maxRows='3'
            label='Распорядок дня'
            fullWidth
            inputProps={{
                style: { resize: 'vertical' }
                }}
            validate={[required()]}
          />
          <FileInputAdmin source='photo' label={'Фотографии'} multiple={false} validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

const SeasonsTour = () => {
  return (
    <>
      <p className='tour-form__title'>{TOURS_FIELDS.season}</p>
      <ul className='tours__checkbox-list'>
        {itemsSeasonTour.map((item) => (
          <li className='tours__checkbox-list__item' key={item.id}>
            <SwitchControl name={item.id} title={item.value} />
          </li>
        ))}
      </ul>
    </>
  );
};

const SelectProductType = () => {
  const { getValues, setValue } = useFormContext();

  const clearNumberDay = ({ target }) => {
    const newValue = target.value;
    const days = getValues('days');
    days?.forEach((day, index) => {
      const timesDay = ['утро', 'день', 'вечер'];
      const includeTimesDay = timesDay.includes(day.numberDay);
      const needClearNumberDay = (newValue === 'Тур' ? includeTimesDay : !includeTimesDay) && !!day.numberDay;
      if (needClearNumberDay) {
        setValue(`days.${index}.numberDay`, '', { shouldTouch: true });
      }
    });
  };

  return (
    <SelectInput
      source='productType'
      label={TOURS_FIELDS.productType}
      fullWidth
      choices={[
        {
          id: TRANSFORM_TEXT.productType.TOUR,
          name: TRANSFORM_TEXT.productType.TOUR,
        },
        {
          id: TRANSFORM_TEXT.productType.EXCURSION,
          name: TRANSFORM_TEXT.productType.EXCURSION,
        },
      ]}
      validate={[required()]}
      onChange={clearNumberDay}
      format={(v) => (v ? v : '')}
    />
  );
};

const ChangeForm = () => {
  const { watch, setValue } = useFormContext();
  const { isDirty } = useFormState();
  const isUploadDraftRef = useRef(false);

  const getSendDraftData = (values) => {
    if (values.id === 'new' || values.id === 'create') {
      delete values.id;
    }
    delete values.preview;
    delete values.photos;
    delete values.habitationPhotosFiles;
    const days = values.days?.map((day) => ({ ...day, photo: null }));
    values.days = days;
    return values;
  };

  const saveDraft = async (values) => {
    isUploadDraftRef.current = true;
    try {
      const { data } = values.id ? await TourService.updateDraft(values) : await TourService.createDraft(values);
      if (!values.id) {
        setValue('id', data.id);
        setValue('status', data.status);
        setValue('operator', data.operator);
        setValue('createdDate', data.createdDate);
      }
      if (data.days?.length) {
        data.days.forEach((day, index) => {
          setValue(`days.${index}.id`, day.id);
        });
      }
      if (data.habitation?.length) {
        data.habitation.forEach((habitation, index) => {
          setValue(`habitation.${index}.id`, habitation.id);
        });
      }
    } catch {
    } finally {
      setTimeout(() => isUploadDraftRef.current = false, 1000);
    }
  };

  const handleWatchForm = (values) => {
    if (!isDirty || isUploadDraftRef.current) return;
    const sendDraftData = getSendDraftData({ ...values });
    saveDraft(sendDraftData);
  };

  useEffect(() => {
    const watcher = watch(debounce(handleWatchForm, 1000));
    return () => watcher.unsubscribe();
  }, [watch, handleWatchForm]);

  return <></>;
};

export const TourForm = (props) => {
  const { isCreate, record, deleteTour, postSave } = props;
  const isDisabledPublic = record && record.status && record.status !== STATUS_TYPE.APPROVED;
  const needSaveDraft = !record || !record.status || record.status === STATUS_TYPE.DRAFT;
  const convertChoice = Object.values(TRANSFORM_TEXT.tourTypeMulti).map((el) => ({ id: el, name: el }));

  const format = (val) => {
    try {
      const res = val ? val.split(';') : [];
      return res;
    } catch (e) {
      return [];
    }
  };

  const parse = (val) => {
    try {
      const res = val ? val.join(';') : '';
      return res;
    } catch (e) {
      return '';
    }
  };

  const userData = useAppSelector((state) => state.user.userData);
  const { admin } = TRANSFORM_TEXT.role;


  return (
    <div className='Amain'>
      <div className='Amain-reviews'>
        <div className='Amain-review'>
          <SimpleForm
            record={record}
            toolbar={<PostEditToolbar isCreate={isCreate} deleteTour={deleteTour} record={record} />}
            onSubmit={(tourData) => postSave(tourData)}
          >
            {needSaveDraft && <ChangeForm />}

            {!isCreate && (
              <Grid container>
                <Grid item>
                  <p className='tour-form__title'>{TOURS_FIELDS.statusPublic}</p>
                </Grid>
                <Grid item xs={2}>
                  <SwitchControl name='isPublished' disabled={isDisabledPublic} />
                </Grid>
              </Grid>
            )}
            <TextInput source='title' label={TOURS_FIELDS.title} fullWidth validate={[required()]} />
            <TextInput
              source='shortDesc'
              format={(v) => (v ? v : '')}
              label={TOURS_FIELDS.shortDesc}
              fullWidth
              validate={[required()]}
              multiline={true}
              inputProps={{
                style: { resize: 'vertical' }
                }}
              minRows='3'
              maxRows='3'
            />
            <FileInputAdmin source='preview' label={TOURS_FIELDS.preview} multiple={false} validate={[required()]} />
            <FileInputAdmin source='photos' label={TOURS_FIELDS.photos} multiple={true} validate={[required()]} />
            <TextInput
              source='operatorEmail'
              format={(v) => (v ? v : '')}
              label={TOURS_FIELDS.operator}
              fullWidth
              validate={[required(), email()]}
            />
            <SelectArrayInput
              source='tourTypeMulti'
              label={TOURS_FIELDS.tourType}
              format={format}
              parse={parse}
              fullWidth
              choices={convertChoice}
              validate={[required()]}
            />

            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <SelectInput
                  source='guide'
                  label={TOURS_FIELDS.guide}
                  fullWidth
                  format={(v) => (v ? v : '')}
                  parse={(v) => (v ? v : null)}
                  emptyValue={null}
                  choices={[
                    {
                      id: TRANSFORM_TEXT.guide.no,
                      name: TRANSFORM_TEXT.guide.no,
                    },
                    {
                      id: TRANSFORM_TEXT.guide.excursovod,
                      name: TRANSFORM_TEXT.guide.excursovod,
                    },
                    {
                      id: TRANSFORM_TEXT.guide.guidTran,
                      name: TRANSFORM_TEXT.guide.guidTran,
                    },
                    {
                      id: TRANSFORM_TEXT.guide.instructor,
                      name: TRANSFORM_TEXT.guide.instructor,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SelectProductType />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <SelectInput
                  source='food'
                  label={TOURS_FIELDS.food}
                  fullWidth
                  format={(v) => (v ? v : '')}
                  parse={(v) => (v ? v : null)}
                  emptyValue={null}
                  choices={[
                    {
                      id: 'true',
                      name: TRANSFORM_TEXT.food.yes,
                    },
                    {
                      id: 'false',
                      name: TRANSFORM_TEXT.food.no,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput
                  source='price'
                  step='1000'
                  format={(v) => (v ? v : '')}
                  label={TOURS_FIELDS.price}
                  fullWidth
                  validate={[required(), minValue(0)]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput
                  source='duration'
                  step={1}
                  label={TOURS_FIELDS.duration}
                  fullWidth
                  validate={[required(), minValue(1)]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput source='minAge' label={TOURS_FIELDS.minAge} fullWidth validate={[minValue(0)]} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput source='maxAge' label={TOURS_FIELDS.maxAge} fullWidth validate={[minValue(0)]} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput
                  source='minGroupSize'
                  label={TOURS_FIELDS.minGroupSize}
                  fullWidth
                  validate={[minValue(1)]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput
                  source='maxGroupSize'
                  label={TOURS_FIELDS.maxGroupSize}
                  fullWidth
                  validate={[minValue(1)]}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SeasonsTour />
              </Grid>
            </Grid>
            <TextInput
              source='transfer'
              format={(v) => (v ? v : '')}
              multiline={true}
              minRows='3'
              label={TOURS_FIELDS.transfer}
              fullWidth
              inputProps={{
                style: { resize: 'vertical' }
                }}
            />
            <TextInput
              source='activities'
              format={(v) => (v ? v : '')}
              multiline={true}
              minRows='3'
              label={TOURS_FIELDS.activities}
              fullWidth
              inputProps={{
                style: { resize: 'vertical' }
                }}
            />
            <TextInput
              source='additionalServices'
              format={(v) => (v ? v : '')}
              multiline={true}
              minRows='3'
              maxRows='3'
              label={TOURS_FIELDS.additionalServices}
              fullWidth
              inputProps={{
                style: { resize: 'vertical' }
                }}
            />
            <LocationsTour />
            <TextInput
              source='benefits'
              format={(v) => (v ? v : '')}
              multiline={true}
              minRows='3'
              maxRows='3'
              label={TOURS_FIELDS.benefits}
              fullWidth
              inputProps={{
                style: { resize: 'vertical' }
                }}
            />
            <DaysTour />
            <ArrayInput source='habitation' sx={styleInBlock} label={TOURS_FIELDS.habitation} fullWidth>
              <SimpleFormIterator disableReordering>
                <TextInput source='title' format={(v) => (v ? v : '')} label='Заголовок' fullWidth />
                <TextInput
                  source='desc'
                  format={(v) => (v ? v : '')}
                  multiline={true}
                  minRows='3'
                  maxRows='3'
                  label='Описание'
                  fullWidth
                  inputProps={{
                  style: { resize: 'vertical' }
                    }}
                />
              </SimpleFormIterator>
            </ArrayInput>
            <FileInputAdmin source='habitationPhotosFiles' label={TOURS_FIELDS.habitationPhoto} multiple={true} />
            <TextInput
              source='included'
              format={(v) => (v ? v : '')}
              label={TOURS_FIELDS.included}
              fullWidth
              multiline={true}
              minRows='3'
              maxRows='3'
              inputProps={{
                style: { resize: 'vertical' }
                }}
            />
            <TextInput
              source='notIncluded'
              format={(v) => (v ? v : '')}
              label={TOURS_FIELDS.notIncluded}
              fullWidth
              multiline={true}
              minRows='3'
              maxRows='3'
              inputProps={{
                style: { resize: 'vertical' }
                }}
            />
          </SimpleForm>
        </div>
      </div>
      { userData.role === admin && <Recommendations /> }
    </div>
  );
};
