import { useDispatch } from 'react-redux';
import mountain from 'assets/images/homePage/flora/mountain.png';
import putorana from 'assets/images/homePage/flora/putorana.png';
import anabar from 'assets/images/homePage/flora/anabar.png';
import water from 'assets/images/homePage/flora/water.png';
import { OpacityContentCard } from 'shared/lib/OpacityContentCard/OpacityContentCard';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { defaultFiltersTours, setFilters } from 'manageStore/tours/tours.slice';
import { TRANSFORM_TEXT } from 'shared/constants/const';

type TCardContentProps = {
  title: string;
  handleClickCard: () => void;
};

const CardContent = ({ title, handleClickCard }: TCardContentProps) => {
  return (
    <>
      <TitleSectionMain title={title} className='home-flora__card__title' />
      <button className='home-flora__card__btn' type='button' onClick={handleClickCard}>
        Хочу посетить
      </button>
    </>
  );
};

export const HomeFlora = () => {
  const dispatch = useDispatch();

  const handleClickCard = (filter: string, type: 'locations' | 'types') => {
    const filters =
      type === 'types' ? { ...defaultFiltersTours, types: [filter] } : { ...defaultFiltersTours, locations: [filter] };
    dispatch(setFilters(filters));

    const newTab = window.open('/tours', '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      console.error('Failed to open new tab. It might be blocked by the browser.');
    }
  };

  return (
    <div className='home-flora'>
      <div className='container home-flora__container'>
        <TitleSectionMain className='home-flora__pretitle' title='Путешествие в затерянный мир Таймырской Арктики' />
        <TitleSectionMain
          className='home-flora__title'
          title='Потрясающая природа'
          subtitle='Уникальная заповедная территория, внесённая в список всемирного природного наследия ЮНЕСКО. Горы, появившиеся из-за глобальной катастрофы планетарного масштаба, во время великого пермского вымирания. Тысячи кристально чистых озер богатых уникальной рыбой. Самые мощные и высокие водопады России!'
        />
        <div className='home-flora__cards-list'>
          <OpacityContentCard className='home-flora__card' srcImage={mountain} altImage='Дальний Таймыр'>
            <CardContent
              title='Дальний Таймыр'
              handleClickCard={() => handleClickCard(TRANSFORM_TEXT.tourTypeMulti.EXPEDITIONARY, 'types')}
            />
          </OpacityContentCard>
          <OpacityContentCard className='home-flora__card' srcImage={putorana} altImage='Плато Путорана'>
            <CardContent
              title='Плато Путорана'
              handleClickCard={() => handleClickCard(TRANSFORM_TEXT.locationsTourForFilter.PUTORANA, 'locations')}
            />
          </OpacityContentCard>
          <OpacityContentCard className='home-flora__card' srcImage={anabar} altImage='Плато Анабар'>
            <CardContent
              title='Плато Анабар'
              handleClickCard={() => handleClickCard(TRANSFORM_TEXT.locationsTourForFilter.ANABAR, 'locations')}
            />
          </OpacityContentCard>
          <OpacityContentCard className='home-flora__card' srcImage={water} altImage='Озера и водопады'>
            <CardContent
              title='Озера и водопады'
              handleClickCard={() => handleClickCard(TRANSFORM_TEXT.tourTypeMulti.WATER, 'types')}
            />
          </OpacityContentCard>
        </div>
      </div>
    </div>
  );
};
