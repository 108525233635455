import { useHistory } from 'react-router-dom';
import { TRANSFORM_TEXT, URL_FOR_IMG } from 'shared/constants/const';
import { declensionNoun } from 'helpers/declensionNoun';
import { TTour } from 'shared/types/tours.types';
import { ReactComponent as RubleIcon } from 'assets/images/common/ruble.svg';
import { getTextFromMinMaxValue } from 'helpers/getTextFromMinMaxValue';

type THomeToursItemProps = {
  tour: TTour;
};

export const HomeToursItem = ({ tour }: THomeToursItemProps) => {
  const history = useHistory();
  const {
    minAge,
    maxAge,
    price,
    preview,
    productType,
    title,
    startMonth,
    endMonth,
    shortDesc,
    minGroupSize,
    maxGroupSize,
  } = tour;
  const priceText = price.toLocaleString('ru-RU');
  const ageText = getTextFromMinMaxValue(minAge, maxAge, { key: 'age' });
  const groupText = getTextFromMinMaxValue(minGroupSize, maxGroupSize, { label: 'чел' });

  let seasonText = '';
  if ((!startMonth || !endMonth) || (startMonth == 1 && endMonth == 12)) {
    seasonText = 'Круглый год';
  } else {
    const monthNames = [
      'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
      'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ];

    seasonText = monthNames[startMonth - 1] + '-' + monthNames[endMonth - 1];
  }
  console.log(tour.engName)

  const openTour = () => {
    history.push(`/tours/${tour.engName}`); 
  };

  return (
    <div className='home-tours__item' onClick={openTour}>
      <div className='home-tours__item__content'>
        <div className='home-tours__item__preview'>
          <img
            className='home-tours__item__img'
            src={`${URL_FOR_IMG}/${preview && preview.path ? preview.path : null}`}
            alt={title}
          />
          {productType === TRANSFORM_TEXT.productType.EXCURSION && (
            <div className='home-tours__item__type'>{TRANSFORM_TEXT.productType.EXCURSION}</div>
          )}
        </div>
        <div className='home-tours__item__description left'>
          <h5 className='home-tours__item__name'>{title}</h5>
          <div className='home-tours__item__params'>
            <div className='home-tours__item__params__data'>
              <div className='home-tours__item__params__name'>Месяцы</div>
              <div className='home-tours__item__params__value'>{seasonText}</div>
            </div>
            <div className='home-tours__item__params__data'>
              <div className='home-tours__item__params__name'>Группа</div>
              <div className='home-tours__item__params__value'>{groupText}</div>
            </div>
            <div className='home-tours__item__params__data'>
              <div className='home-tours__item__params__name'>Возраст</div>
              <div className='home-tours__item__params__value'>{ageText}</div>
            </div>
          </div>
          <p className='home-tours__item__text'>{shortDesc}</p>
        </div>
        <div className='home-tours__item__description right'>
          <div className='home-tours__item__params__value price'>
            от {priceText} <RubleIcon className='ruble-icon' />
          </div>
          <div className='home-tours__item__params__value duration'>
            {`${tour.duration} ${declensionNoun(tour.duration, 'day')}`}
          </div>
        </div>
      </div>
    </div>
  );
};
