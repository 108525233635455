import classNames from 'classnames';
import { ReactComponent as SelectedIcon } from 'assets/images/common/select/selected.svg';

type TBaseCheckboxProps = {
  isActive: boolean;
  label?: JSX.Element | string | undefined;
  className?: string;
  isDark?: boolean;
  isReverse?: boolean;
  isSpaceBetween?: boolean;
  isMobile?: boolean;
  isButton?: boolean;
};

export const BaseCheckbox = ({
  className,
  label,
  isActive,
  isDark,
  isReverse,
  isSpaceBetween,
  isMobile,
  isButton,
}: TBaseCheckboxProps) => {
  return (
    <div
      className={classNames('base-checkbox', className, {
        reverse: isReverse,
        'space-between': isSpaceBetween,
        mobile: isMobile,
        'check-button': isButton,
      })}
    >
      <div className={classNames('base-checkbox__input', { active: isActive, dark: isDark })}>
        <SelectedIcon />
      </div>
      <div className={classNames('base-checkbox__label', { active: isActive, dark: !isDark })}>{label}</div>
    </div>
  );
};
