import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CommonService from 'services/common.service';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import classNames from 'classnames';
import { BaseCheckbox } from '../../../../shared/ui/BaseCheckbox/BaseCheckbox';
import { useMediaQuery } from '../../../../shared/hooks/useMatchMedia';

export const FeedbackForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [saving, setSaving] = useState(false);
  const handleSaving = () => setSaving((prev) => !prev);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Заполните поле'),
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      message: Yup.string().required('Заполните поле'),
    }),
    onSubmit: async (values) => {
      handleSaving();
      try {
        await Notification.promise(CommonService.feedback(values), true, 3000, 'Ваш вопрос получен');
        formik.resetForm();
      } catch (e) {
        Notification.error(handleError(e));
        throw e;
      } finally {
        handleSaving();
      }
    },
    enableReinitialize: true,
  });
  return (
    <form className='feedback-form'>
      <div className='feedback-form__input-group'>
        <input className='feedback-form__input' id='name' type='text' {...formik.getFieldProps('name')} />
        <label htmlFor='name' className={classNames('placeholder', { placeholder_top: !!formik.values.name })}>
          Имя
        </label>
        <p className='feedback-form__input-error'>
          {formik.touched.name && formik.errors.name ? formik.errors.name : ''}
        </p>
      </div>
      <div className='feedback-form__input-group'>
        <input className='feedback-form__input' id='email' type='email' {...formik.getFieldProps('email')} />
        <label htmlFor='email' className={classNames('placeholder', { placeholder_top: !!formik.values.email })}>
          Email
        </label>
        <p className='feedback-form__input-error'>
          {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
        </p>
      </div>
      <div className='feedback-form__textarea-group'>
        <textarea className='feedback-form__textarea' id='message' {...formik.getFieldProps('message')} />
        <label
          htmlFor='message'
          className={classNames('placeholder', { placeholder_opacity: !!formik.values.message })}
        >
          Текст сообщения
        </label>
        <p className='feedback-form__input-error'>
          {formik.touched.message && formik.errors.message ? formik.errors.message : ''}
        </p>
      </div>
      <li onClick={() => setIsChecked((prev) => !prev)} className={'checkbox'}>
        <BaseCheckbox
          label={
            <>
              Согласен на обработку{' '}
              <a className='highlighted-text' href={'useragreement'}>персональных данных</a>
            </>
          }
          isActive={isChecked}
          isDark={!isMobile}
          isMobile={isMobile}
          isButton={isMobile}
        />
      </li>
      <button
        className='feedback-form__btn'
        type='button'
        disabled={saving || !isChecked}
        onClick={() => formik.handleSubmit()}
      >
        Отправить
      </button>
    </form>
  );
};
