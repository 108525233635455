import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Admin, Resource, useRecordContext, useGetOne, useUpdate, useCreate, useDelete } from 'react-admin';
import { QueryClient } from 'react-query';
import { TourForm } from './TourForm';
import Loading from 'components/Loading/Loading';
import adminApi from 'http/adminApi';
import Notification from 'shared/lib/notification';
import { adminTheme } from '../../Admin/adminTheme';
import { useEffect, useState } from 'react';

const EmptyNotification = () => {
  return <></>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Dashboard = () => {
  const { pathname } = useLocation();
  // eslint-disable-next-line no-restricted-globals
  const hash = location.hash;
  const isDraft = pathname.includes('/draft');
  const baseReource = 'tours';
  const getResource = isDraft ? `${baseReource}/draft` : baseReource;
  const { id: paramsId } = useParams();
  const [id, setID] = useState(paramsId || hash.split('/').pop());

  useEffect(() => {
    const extractedId = hash.split('/').pop();
    if (extractedId) {
      setID(extractedId);
    }
  }, [hash, paramsId]);


  const history = useHistory();

  useEffect(() => {
    if (id === 'create') {
      const newHash = hash.replace(id, 'new');
      history.replace({ pathname, hash: newHash });
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }, [id, history, pathname, hash]);
  
 
  const getTour = useGetOne(getResource, { id }, {
    enabled: !!id,
    onError: (e) => Notification.error(e || 'Произошла ошибка!'),
  });

  const [update, statusUpdate] = useUpdate();
  const [create, statusCreate] = useCreate();
  const [deleteOne, statusDelete] = useDelete();

  const isCreate = id === 'new' || id === 'create';

  const isLoadingData = getTour.isLoading || statusUpdate.isLoading || statusCreate.isLoading || statusDelete.isLoading;
  const initRecord = isCreate ? { recommendations: [], recommendedTours: [], habitation: [], locations: [], days: [] } : {};
  const record = useRecordContext({ record: { ...getTour.data, ...initRecord } });

  const postSave = async (tourData) => {
    const isUpdate = tourData.id && tourData.id !== 'new' && tourData.id !== 'crate';
    const request = isUpdate ? update : create;
    const data = isUpdate ? { id: tourData.id, data: tourData } : { data: tourData };
    await request(baseReource, data, {
      onError: (e) => Notification.error(e || 'Произошла ошибка!'),
      onSuccess: () => {
        history.push('/account/operator/tours');
        Notification.success('Успешно');
      },
    });
  };

  const deleteTour = async () => {
    await deleteOne(
      baseReource,
      { id },
      {
        onError: (e) => Notification.error(e || 'Произошла ошибка!'),
        onSuccess: () => {
          Notification.success('Успешно');
          history.push('/account/operator/tours');
        },
      }
    );
  };

  useEffect(() => {
    console.log(record, id);
  }, [record, id]);

  if (!record && !isCreate) return isLoadingData ? <Loading /> : null;

  return (
    <>
      { isLoadingData && <Loading /> }
      <TourForm isCreate={ isCreate } id={ id } record={ record } deleteTour={ deleteTour } postSave={ postSave } />
    </>
  );
};

const EmptyLayout = ({ children }) => <>{ children }</>;

const TourShow = () => {
  return (
    <Admin
      layout={ EmptyLayout }
      theme={ adminTheme }
      dashboard={ Dashboard }
      dataProvider={ adminApi }
      notification={ EmptyNotification }
      queryClient={ queryClient }
    >
      <Resource name='empty' />
    </Admin>
  );
};

export default TourShow;
