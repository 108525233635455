import { MouseEvent, useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectBlogList, setBlogsList } from 'manageStore/news/newsSlice';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import Loading from 'components/Loading/Loading';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { URL_FOR_IMG } from '../../../../shared/constants/const';
import { TNew } from 'shared/types/types';
import { useClientRect } from 'shared/hooks/useClientRect';
import { useSwipe } from 'shared/hooks/useSwipe';
import BlogService from '../../../../services/blog.service';
import { useMediaQuery } from '../../../../shared/hooks/useMatchMedia';

type TNewsItemProps = {
  item: TNew;
  openNew: (id: number) => void;
  endSwipe: () => void;
};

const settings: Settings = {
  dots: false,
  infinite: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right dark />,
  prevArrow: <ButtonSlider left dark />,
};

const NewsItem = ({ item, openNew, endSwipe }: TNewsItemProps) => {
  const handleClickSlide = ({ currentTarget }: MouseEvent<HTMLDivElement>) => {
    const slideElement = currentTarget.closest('.slick-slide');
    if (slideElement?.classList.contains('slick-active')) {
      openNew(item.id);
    }
  };

  const dateText = new Date(item.date).toLocaleDateString(undefined, { month: 'long', day: 'numeric' });
  const descriptionText = `${item.desc.slice(0, 180)}...`;
  const src = `${URL_FOR_IMG}/${item.preview && item.preview.path ? item.preview.path : null}`;

  return (
    <article className='home-news__article' onClick={handleClickSlide} onMouseDown={endSwipe}>
      <div className='home-news__item'>
        <p className='home-news__item-date'>{dateText}</p>
        <h5 className='title-section small home-news__item-title'>{item.title}</h5>
        <p className='home-news__item-text'>{descriptionText}</p>
        <div className='home-news__item-img'>
          <img src={src} alt='Превью новости' />
          <div className='img-cover'></div>
        </div>
      </div>
    </article>
  );
};

export const HomeNews = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [paddingSlider, setPaddingSlider] = useState(100);

  const [sectionRect, sectionRef] = useClientRect();
  const [startSwipe, endSwipe, isSwipe] = useSwipe();

  const openNew = (id: number) => {
    if (!isSwipe) {
      const newTab = window.open(`blog/${id}`, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. It might be blocked by the browser.');
      }
    }
  };

  useEffect(() => {
    if (!sectionRect?.width) return;
    const beetwenPadding = window.innerWidth < 1124 ? -8 : 20;
    const minCenterPadding = Math.abs(beetwenPadding * 3);
    const centerPadding = Math.max((sectionRect.width - 1200) / 2 - beetwenPadding, minCenterPadding);
    setPaddingSlider(centerPadding);
  }, [sectionRect]);

  const blogs = useSelector(selectBlogList);
  const getBlogs = async () => {
    try {
      setIsLoading(true);
      const response = await BlogService.getBlogs();
      if (response.data.length) {
        dispatch(setBlogsList(response.data));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fd = async () => {
      await getBlogs();
    };
    fd();
  }, []);

  const [isMobile] = useMediaQuery();

  return (
    <section className='home-news'>
      <div className='container home-news__container'>
        <div className='home-news__inner' ref={sectionRef}>
          <TitleSectionMain className='home-news__title' title='Наш блог' isSmall />
          {isLoading ? (
            <Loading />
          ) : blogs.length ? (
            <Slider
              className='home-news__slider'
              {...settings}
              swipeEvent={startSwipe}
              centerPadding={`${paddingSlider}px`}
            >
              {blogs.map((card) => (
                <NewsItem item={card} key={card.id} openNew={openNew} endSwipe={endSwipe} />
              ))}
            </Slider>
          ) : (
            <>Уупс, пока что ничего нет!</>
          )}
          <Link className='home-news__btn' to={'/blog'}>
            Все статьи
            <ArrowButton />
          </Link>
        </div>
      </div>
    </section>
  );
};
