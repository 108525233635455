import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './SearchComponent.scss';
import SearchService from 'services/search.service';

type TSearchResult = {
  id: number;
  title: string;
  sourceTable: string;
  recordId: string;
};

const Search: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<TSearchResult[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);

  const handleSearchClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      if (value.trim()) {
        performSearch(value.trim());
      } else {
        setResults([]);
      }
    }, 500);
    setTypingTimeout(timeout);
  };

  const performSearch = async (searchQuery: string) => {
    setIsLoading(true);
    try {
      const response = await SearchService.search(searchQuery);
      setResults(response.data);
    } catch (error) {
      console.error('Ошибка запроса:', error);
      setResults([]);
    }
    setIsLoading(false);
  };

  const linkAndLabelMap: Record<string, { link: (recordId: string) => string, label: string }> = {
    blog: {
      link: (recordId) => `/blog/${recordId}`,
      label: 'Блог - ',
    },
    tour: {
      link: (recordId) => `/tours/${recordId}`,
      label: 'Тур - ',
    },
    partner: {
      link: (recordId) => `/partners/${recordId}`,
      label: 'Партнёр - ',
    },
    guidebook: {
      link: (recordId) => `/travel-guides/${recordId}`,
      label: 'Путеводитель - ',
    },
  };

  const generateLink = (sourceTable: string, recordId: string): string => {
    return linkAndLabelMap[sourceTable]?.link(recordId) || '#';
  };

  const getLabel = (sourceTable: string): string => {
    return linkAndLabelMap[sourceTable]?.label || '';
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="search-component">
        <div className="search-icon" onClick={handleSearchClick}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M17.1527 15.0943H16.0618L15.6844 14.717C17.0292 13.1595 17.8388 11.1355 17.8388 8.91938C17.8388 3.99314 13.8456 0 8.91938 0C3.99314 0 0 3.99314 0 8.91938C0 13.8456 3.99314 17.8388 8.91938 17.8388C11.1355 17.8388 13.1595 17.0292 14.717 15.6913L15.0943 16.0686V17.1527L21.9554 24L24 21.9554L17.1527 15.0943ZM8.91938 15.0943C8.10843 15.0943 7.30543 14.9345 6.55622 14.6241C5.80702 14.3138 5.12628 13.8589 4.55289 13.2854C3.39486 12.1272 2.74433 10.5565 2.74443 8.9187C2.74452 7.28091 3.39521 5.71023 4.55337 4.5522C5.71153 3.39417 7.28228 2.74365 8.92007 2.74374C10.5579 2.74383 12.1285 3.39453 13.2866 4.55269C14.4446 5.71084 15.0951 7.28159 15.095 8.91938C15.0949 10.5572 14.4442 12.1279 13.2861 13.2859C12.1279 14.4439 10.5572 15.0944 8.91938 15.0943Z'
              fill='#FFFFFF'
              />
            </svg>
        </div>
      </div>
      <div className={classNames('search-box', { open: isOpen })} ref={searchBoxRef}>
        <input
          type="text"
          className={classNames('search-input', { 'search-input--open': isOpen })}
          value={query}
          onChange={handleInputChange}
          placeholder="Введите запрос"
        />
        {isLoading && <div className="loading-spinner">Загрузка...</div>}
        {results.length > 0 && (
          <ul className="search-results">
            {results.map((result) => (
              <li key={result.id} className="search-result-item">
                <a href={generateLink(result.sourceTable, result.recordId)}>
                  <strong>{getLabel(result.sourceTable)}{result.title}</strong>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Search;
